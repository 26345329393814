<template>
  <div class="col-12 col-lg-3">
    <div class="action--card">
      <Info :developer_image="developer_image" :developer_web="developer_web" :name="name" />
      <div v-if="call">
        <div class="text-center mb-2">
          {{ $t('general.contactUsQuestion') }}
        </div>
        <a :href="call" target="_blank" class="btn btn--whatsapp action--button">
          <img src="@/assets/img/icons/whatsapp-1.svg" alt="Whatsapp" />
          <span>{{ $t('general.contactUs') }}</span>
        </a>
        <!-- <div class="form-group mt-3">
                    <label class="mb-0" for="inputName">Nama Anda</label>
                    <input type="text" class="form-control" id="inputName" aria-describedby="name" placeholder="Masukan Nama Anda">
                </div>
                <div class="form-group mt-3">
                    <label class="mb-0" for="inputNumber">Nomor Telp</label>
                    <input type="text" class="form-control" id="inputNumber" aria-describedby="numberHp" placeholder="Masukan No Handphone">
                </div>
                <div class="form-group mt-3">
                    <label class="mb-0" for="inputEmail">Alamat Email</label>
                    <input type="email" class="form-control" id="inputEmail" aria-describedby="addressEmail" placeholder="Masukan Alamat Email">
                </div>
                <button class="btn btn--primary w-100">
                    Kabari Saya
                </button> -->
      </div>
    </div>
  </div>
</template>

<script>
const Info = () => import('@/components/project-partner/partials/info');
export default {
  props: {
    developer_image: {
      type: String,
    },
    developer_web: {
      type: String,
    },
    name: {
      type: String,
    },
    call: {
      type: String,
    },
    number: {
      type: String,
    },
  },
  components: {
    Info,
  },
};
</script>

<style></style>
